import * as React from "react";
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import { getDomain } from "../Auth/AuthConfiguration";
import { SCREEN_PATHS as PRM_PATHS } from "../../RPT/constants/constants"
import { SCREEN_PATHS as AGM_PATHS } from "../../AGM/constants/constants"

const domain: string = getDomain();

const SideNavPanel: React.FC<any> = ({ navigate }) => {
    const [activeHref, setActiveHref] = React.useState("/");
    return (
        <SideNavigation
            className="sideNav"
            activeHref={activeHref}
            header={{ href: "#/", text: "Applications" }}
            onFollow={(event) => {
                if (!event.detail.external) {
                    setActiveHref(event.detail.href);
                }
            }}
            items={[
                // { type: "link", text: "Verity", href: "#/verity/scenario-config" },
                { type: "link", text: "Program Roadmap Manager (PRM)", href: PRM_PATHS.basePath },
                { type: "link", text: "ASIN Generation Manager (AGM)", href: AGM_PATHS.basePath },
                { type: "link", text: "Charts of Account (COA)", href: "#/coa/accessoryProject" },
            ]}
        />
    );
};

export const SideNav = SideNavPanel;
