import { InputProps } from "@cloudscape-design/components";
import { PrfaqApprovalStatus } from "../interfaces/interfaces";

export interface IFieldMetaData {
    label: string
    type: any
    inputType?: InputProps.Type
    row?: number
    placeholder?: string
    readonly?: boolean
    options?: any
    actions?: any
    filteringType?: "none" | "manual" | "auto"
    fullWidth?: boolean
    visibility?: boolean
    validation?: any
    onBlur?: any
}

export const FIELD_NAMES = {
    createRpnRpn: 'rpn',
    specsDispEnabled : 'Display enabled',
    specsDispAttr: 'Display attributes',
    specsMicEnabled : 'Mic enabled',
    specsMicRange : 'Mic field range',
    specsProjCoa: 'Project COA',
    specsSoCName: 'SoC name',
    specsNewSoC: "New SoC",
    SpecsOS: "OS",
    SpecsSoCPartiness: "SoC partiness",
    SpecsSoCName: "SoC name",
    specsAlexaEnabled: "Alexa enabled",
    specsCameraEnabled: "Camera enabled",
    specsSoCNameOther: 'SoC name Other',
    financialBsnJustification: 'Business justification',
    financialRmrsePolicy : 'Remorse policy',
    programPredecessor: 'Program predeccessor',
    programComplexity: 'Program complexity',
    productTier: 'Product tier',
    productSubCategory: 'Product sub-category',
    programFollowOn: 'Program follow-on',
    programDesc: 'Program description',
    comments: 'Comments',
    majorCallOuts: 'Major call outs',
    ContactAddInfo: 'Contacts for Additional Info',
    fsdCountry: 'FSD Countries',
    fsdate: 'FSD Date',
    PMOwners: "PM Owner(s)",
    USMSRP: "US MSRP(USD)",
    warrantyOffering: "Warranty offering",
    partiness: "Partiness",
    productLifeInMonths: "Product life (in months)",
    globalLifeVolumeEst: "Global Life Volume Est",
};

const generateRowNumbers = (specs: Record<string, Omit<IFieldMetaData, 'row'>>, maxItemsPerRow: number) => {
    let currentRow = 1;
    let itemsInCurrentRow = 0;

    return Object.entries(specs).reduce((acc, [key, value]) => {
        if (itemsInCurrentRow >= maxItemsPerRow) {
            currentRow++;
            itemsInCurrentRow = 0;
        }

        acc[key] = {
            ...value,
            row: currentRow
        };

        itemsInCurrentRow++;
        return acc;
    }, {} as Record<string, IFieldMetaData>);
};

export const CREATE_PROGRAM : Record<string, IFieldMetaData> = {
    "rpn": {
        label: "Program name",
        type: "input",
        placeholder: "Enter program name",
        row: 1
    },
    "product_line": {
        label: "Product line",
        type: "select",
        placeholder: "Select a product line",
        row: 2,
        filteringType: 'auto'
    },
    "is_name_approved": {
        label: "Is name approved",
        type: "radio",
        options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
        ],
        row: 3
    },
    "is_brd_approved": {
        label: "Is BRD approved",
        type: "radio",
        options: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" }
        ],
        row: 3
    },
    "prfaq_approval_status": {
        label: "Is PRFAQ approved",
        type: "radio",
        options: [
            { value: PrfaqApprovalStatus.Yes, label: "Yes" },
            { value: PrfaqApprovalStatus.No, label: "No" },
            { value: PrfaqApprovalStatus.NA, label: "N/A" },
        ],
        row: 3
    },
    "prfaq_link": {
        label: "PRFAQ link",
        type: "input",
        placeholder: "Please provide if PRFAQ approved",
        fullWidth: true,
        row: 4
    },
    "comment": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const PROGRAM_OVERVIEW : Record<string, IFieldMetaData> = {
    "Confidential": {
        label: "Confidential",
        type: "toggle",
        row: 1
    },
    [FIELD_NAMES.programPredecessor]: {
        label: "Program predecessor",
        type: "select",
        row: 2,
        filteringType: 'auto'
    },
    [FIELD_NAMES.programComplexity]: {
        label: FIELD_NAMES.programComplexity,
        type: "select",
        row: 2,
        options: [
            {label:"v0/v1", value:"v0/v1"}, 
            {label:"vNext", value:"vNext"}, 
            {label:"vNext+NUDD", value:"vNext+NUDD"},
            {label: "N/A", value: "N/A"},
        ]
    },
    [FIELD_NAMES.productTier]: {
        label: FIELD_NAMES.productTier,
        type: "select",
        row: 2,
        options: [
            {label:"Entry", value:"Entry"}, 
            {label:"Core", value:"Core"}, 
            {label:"Signature", value:"Signature"},
            {label: "N/A", value: "N/A"},
        ]
    },
    [FIELD_NAMES.productSubCategory]: {
        label: FIELD_NAMES.productSubCategory,
        type: "select",
        row: 2,
        options: [
            {label: "Headless", value: "Headless"},
            {label: "Multimodal", value: "Multimodal"},
            {label: "On-the-Go", value: "On-the-Go"},
            {label: "Stick HD", value: "Stick HD"},
            {label: "Stick 4K Select", value: "Stick 4K Select"},
            {label: "Stick 4k Plus", value: "Stick 4k Plus"},
            {label: "Stick 4k Max", value: "Stick 4k Max"},
            {label: "FireTV Cube", value: "FireTV Cube"},
            {label: "Far Field 4K TV (Mini-Led)", value: "Far Field 4K TV (Mini-Led)"},
            {label: "Far Field 4K TV (QLED)", value: "Far Field 4K TV (QLED)"},
            {label: "Far Field 4K TV", value: "Far Field 4K TV"},
            {label: "Near Field 4K TV", value: "Near Field 4K TV"},
            {label: "Near Field 2K TV", value: "Near Field 2K TV"},
            {label: "Scribe", value: "Scribe"},
            {label: "Colorsoft", value: "Colorsoft"},
            {label: "Paperwhite", value: "Paperwhite"},
            {label: "Kindle", value: "Kindle"},
            {label: "12\" Tablet", value: "12\" Tablet"},
            {label: "10\"-11\" Tablet", value: "10\"-11\" Tablet"},
            {label: "8\" Tablet", value: "8\" Tablet"},
            {label: "7\" Tablet", value: "7\" Tablet"},
            {label: "Robotics-Home Assistant", value: "Robotics-Home Assistant"},
            {label: "Smart Eyewear - Enterprise", value: "Smart Eyewear - Enterprise"},
            {label: "Smart Eyewear - Consumer", value: "Smart Eyewear - Consumer"},
            {label: "Doorbell", value: "Doorbell"},
            {label: "Camera - Indoor/Outdoor", value: "Camera - Indoor/Outdoor"},
            {label: "Camera - Outdoor Spotlight", value: "Camera - Outdoor Spotlight"},
            {label: "Camera - Outdoor Floodlight", value: "Camera - Outdoor Floodlight"},
            {label: "Mobilty", value: "Mobilty"},
            {label: "Alarm", value: "Alarm"},
            {label: "WiFi x PoE", value: "WiFi x PoE"},
            {label: "Multi Gig Tri band", value: "Multi Gig Tri band"},
            {label: "PoE Gateway", value: "PoE Gateway"},
            {label: "Outdoor PoE", value: "Outdoor PoE"},
            {label: "LTE cellular companion", value: "LTE cellular companion"},
            {label: "Analog telephone adapter companion", value: "Analog telephone adapter companion"},
            {label: "Ethernet Switching Portfolio", value: "Ethernet Switching Portfolio"},
            {label: "WiFi x Tri band", value: "WiFi x Tri band"},
            {label: "WiFi x Dual band", value: "WiFi x Dual band"},
            {label: "Other", value: "Other"},
            {label: "N/A", value: "N/A"}
        ]
    },
    [FIELD_NAMES.majorCallOuts]: {
        label: "Major call outs",
        type: "textArea",
        placeholder: "Enter major call outs",
        row: 3
    },
    [FIELD_NAMES.programDesc]: {
        label: "Program description",
        type: "textArea",
        placeholder: "Enter description",
        row: 3
    },
    [FIELD_NAMES.comments]: {
        label: "Program comments",
        type: "textArea",
        placeholder: "Enter comments",
        row: 4
    },
    "Compatible devices": {
        label: "Compatible devices",
        type: "textArea",
        placeholder: "Only applicable for Accessories. Enter compatible devices separated by ';'",
        row: 4
    }
};

export const CONTACT_INFO : Record<string, IFieldMetaData> = {
    "Contacts for Additional Info": {
        label: "Contacts for Additional Info",
        type: "userSearchMultiselect",
        row: 1
    },
    "PM Owner(s)": {
        label: "PM Owner(s)",
        type: "userSearchMultiselect",
        row: 1
    },
    "Accessory Team POC": {
        label: "Accessory Team POC",
        type: "userSearchMultiselect",
        row: 2
    },
    "Others": {
        label: "Others",
        type: "userSearchMultiselect",
        row: 2
    }
};

export const OTHER_KEY_DATES : string[] = [
    "Last PDP Gate / Month", 
    "Last Freshlot Sale Month / Qtr", 
    "End of Software Support", 
    "End of Security Upgrades", 
    "Product Announce (PA) Date", 
    "End of Manufacturing", 
    "End of life"
];

export const FINANCIAL_INFO : Record<string, IFieldMetaData> = {
    "US MSRP(USD)": {
        label: "US MSRP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 1
    },
    "WW MSRP(USD)": {
        label: "WW MSRP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 1
    },
    "WW ASP(USD)": {
        label: "WW ASP(USD)",
        type: "input",
        inputType: "number",
        placeholder:"Enter dollar amount",
        row: 2
    },
    "WW net promo %": {
        label: "WW net promo %",
        type: "input",
        inputType: "number",
        placeholder:"Enter net promo",
        row: 2
    },
    "Warranty offering": {
        label: "Warranty offering",
        type: "select",
        row: 3,
        options: [
            {label:"3 mo", value:"3 mo"}, 
            {label:"12 mo", value:"12 mo"}, 
            {label:"US/JP:3 mo / ROW:12 mo", value:"US/JP:3 mo / ROW:12 mo"}, 
            {label:"12mo / KIDS:24mo", value:"12mo / KIDS:24mo"},
            {value: "N/A", label: "N/A"},
        ]
    },
    [FIELD_NAMES.financialRmrsePolicy]: {
        label: "Remorse policy",
        type: "input",
        row: 4,
        placeholder: "Enter remorse policy (eg: 30)"
    },
    [FIELD_NAMES.financialBsnJustification]: {
        label: "Business justification",
        type: "textArea",
        row: 4,
        placeholder: "Enter business justification for remorse policy",
        visibility: false
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const MANUFACTORING_INFO : Record<string, IFieldMetaData> = {
    "Partiness": {
        label: "Partiness",
        type: "select",
        row: 1,
        options: [{label:"1P", value:"1P"}, {label:"2P", value:"2P"}, {label:"3P", value:"3P"}]
    },
    "Manufacturing: Engagement": {
        label: "Manufacturing: Engagement",
        type: "select",
        options: [{label:"Low", value:"Low"}, {label:"Med", value:"Med"}, {label:"High", value:"High"}],
        row: 1
    },
    "Number of manufacturers": {
        label: "Number of manufacturers",
        type: "input", 
        row: 2,
        inputType: "number"
    },
    "Manufacturer: company": {
        label: "Manufacturer: company",
        type: "input",
        row: 2
    },
    "Product life (in months)": {
        label: "Product life (in months)",
        type: "input", 
        row: 3,
        inputType: "number"
    },
    "Cost center code": {
        label: "Cost center code",
        type: "select",
        row: 3
    },
    "Project HW Id": {
        label: "Project HW Id",
        type: "input",
        row: 4
    },
    "HW program size": {
        label: "HW program size",
        type: "select",
        options: [{label:"XS", value:"XS"}, {label:"S", value:"S"}, {label:"M", value:"M"}, {label:"L", value:"L"}, {label:"XL", value:"XL"}],
        row: 4
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 5
    }
};

export const MARKET_INFO : Record<string, IFieldMetaData> = {
    "Global Life Volume Est": {
        label: "Global Life Volume Est",
        type: "input",
        inputType: "number",
        row: 1
    },
    "Primary or Expansion Launch": {
        label: "Primary or Expansion Launch",
        type: "select",
        options: [{label:"Primary", value:"Primary"}, {label:"Expansion", value:"Expansion"}],
        row: 1
    },
    "Significance Vol at Resellers": {
        label: "Significance Vol at Resellers",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}, {label:"TBD", value:"TBD"}],
        row: 2
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
        row: 3
    }
};

const SPECS_WITHOUT_ROW: Record<string, Omit<IFieldMetaData, 'row'>> = {
    [FIELD_NAMES.specsAlexaEnabled]: {
        label: FIELD_NAMES.specsAlexaEnabled,
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}]
    },
    [FIELD_NAMES.specsCameraEnabled]: {
        label: FIELD_NAMES.specsCameraEnabled,
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}]
    },
    [FIELD_NAMES.SpecsOS]: {
        label: FIELD_NAMES.SpecsOS,
        type: "select",
        options: [
            {label:"FOS", value:"FOS"}, 
            {label:"Vega", value: "Vega"}, 
            {label:"Android", value: "Android"},
            {label:"Other", value: "Other"},
            {label: "N/A", value: "N/A"}
        ]
    },
    [FIELD_NAMES.specsNewSoC]: {
        label: FIELD_NAMES.specsNewSoC,
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}]
    },
    [FIELD_NAMES.SpecsSoCPartiness]: {
        label: FIELD_NAMES.SpecsSoCPartiness,
        type: "select",
        options: [
            {label:"1P", value:"1P"}, 
            {label:"3P", value:"3P"},
            {label: "N/A", value: "N/A"}
        ]
    },
    [FIELD_NAMES.SpecsSoCName]: {
        label: FIELD_NAMES.SpecsSoCName,
        type: "select",
        options: [
            { label: "Indica", value: "Indica" },
            { label: "Rubra", value: "Rubra" },
            { label: "Seratta", value: "Seratta" },
            { label: "Tehama", value: "Tehama" },
            { label: "3P-other", value: "3P-other"},
            { label: "Other", value: "Other" },
            { label: "N/A", value: "N/A" },
        ],
    },
    [FIELD_NAMES.specsProjCoa]: {
        label: "Project COA",
        type: "input",
        placeholder: "Enter Project COA",
    },
    [FIELD_NAMES.specsDispEnabled]: {
        label: "Display enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
    },
    [FIELD_NAMES.specsDispAttr]: {
        label: "Display attributes",
        type: "select",
        visibility: false,
        options: [{label:"S", value:"S"}, {label:"M", value: "M"}, {label:"L", value: "L"}, {label:"N/A", value: "N/A"}, {label:"TBD", value: "TBD"}],
    },
    [FIELD_NAMES.specsMicEnabled]: {
        label: "Mic enabled",
        type: "select",
        options: [{label:"Yes", value:"Yes"}, {label:"No", value: "No"}],
    },
    [FIELD_NAMES.specsMicRange]: {
        label: "Mic field range",
        type: "select",
        visibility: false,
        options: [{label:"NEAR", value:"NEAR"}, {label:"MID", value: "MID"},  {label:"FAR", value: "FAR"}],
    },
    "Comments": {
        label: "Comments",
        type: "textArea",
        placeholder: "Enter comments",
        fullWidth: true,
    },
};

export const SPECS: Record<string, IFieldMetaData> = generateRowNumbers(SPECS_WITHOUT_ROW, 2);
